
























import { Component, Vue, Prop } from 'vue-property-decorator';
import UserStatus from '../components/UserStatus.vue';
import { ServicePTTConfig } from 'core';

@Component({
  components: {
    UserStatus
  }
})
export default class ServiceConfigPTT extends Vue {
  @Prop()
  public value!: ServicePTTConfig;

  public rules = [
    (value: string) => !!value || '請輸入',
    (value: string) => (value && value.length >= 3) || '至少3個長度'
  ];
}
