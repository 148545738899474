























import { Component, Vue } from 'vue-property-decorator';
import { FirebaseUtils, SimpleUserInfo } from '../models/firebaseUtils';
import { WebAPIs } from '../models/apis';
import { UserAPI } from '../models/userAPI';
import { ToastMessage } from '../models/toastMessage';
import { User } from 'core';

@Component({ components: {} })
export default class UserStatus extends Vue {
  public loading: boolean = false;
  public fbUser: SimpleUserInfo | null = null;
  public user: User | null = null;
  public couponId: string = '';
  public redeeming: boolean = false;

  public async mounted() {
    this.loadUser();
  }

  public async loadUser() {
    this.loading = true;
    this.fbUser = await FirebaseUtils.getFirebaseUserStatus();
    if (this.fbUser === null) {
      return;
    }
    try {
      const user = await UserAPI.getUser(true);
      this.$set(this, 'user', user);
    } catch (e) {
      ToastMessage.httpRequestErrorHandler(this, e);
    }
    this.loading = false;
  }

  public async redeemCoupon() {
    this.redeeming = true;
    try {
      const coupon = await WebAPIs.redeemCoupon(this.couponId);
      ToastMessage.simpleDialog(this, '加值成功', `已成功加值 ${coupon.points} 點`);
      this.couponId = '';
      await this.loadUser();
    } catch (e) {
      ToastMessage.httpRequestErrorHandler(this, e);
    }
    this.redeeming = false;
  }
}
