import { WebAPIs } from './apis';
import { FirebaseUtils } from './firebaseUtils';
import { User } from 'core';

export class UserAPI {
  private static user: Promise<User | null> | undefined = undefined;

  public static getUser(forceUpdate: boolean = false): Promise<User | null> {
    if (forceUpdate) {
      UserAPI.user = undefined;
    }
    if (UserAPI.user === undefined) {
      UserAPI.user = new Promise<User | null>(async resolve => {
        const fbUser = await FirebaseUtils.getFirebaseUserStatus();
        if (fbUser === null) {
          resolve(null);
          return;
        }
        const user = await WebAPIs.register(fbUser.name, fbUser.email, fbUser.avatar);
        resolve(user);
      });
    }
    return UserAPI.user;
  }
}
