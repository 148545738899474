import firebase from 'firebase/app';
import { ServiceTag, ServiceTask, User, Coupon } from 'core';

export class WebAPIs {
  public static async register(username: string, email: string, avatar: string): Promise<User> {
    const result = await firebase.functions().httpsCallable('userAPI-register')({
      username,
      email,
      avatar
    });
    return result.data;
  }

  public static async getUserServiceTasks(): Promise<ServiceTask[]> {
    const result = await firebase.functions().httpsCallable('ServiceAPI-getUserTasks')({});
    return result.data;
  }

  public static async updateServiceActive(serviceId: string, active: boolean): Promise<void> {
    await firebase.functions().httpsCallable('ServiceAPI-updateServiceActive')({
      serviceId,
      active
    });
  }

  // public static async genPTTServicePaymentHTML(planId: string): Promise<string> {
  //   const result = await firebase.functions().httpsCallable('ECPayAPI-genPTTServicePaymentHTML')({
  //     planId
  //   });
  //   return result.data;
  // }

  public static async updateServiceConfig(serviceId: string, config: any): Promise<ServiceTask> {
    const result = await firebase.functions().httpsCallable('ServiceAPI-updateServiceConfig')({
      serviceId,
      config
    });
    return result.data;
  }

  public static async createServiceTask(tag: ServiceTag, config: any): Promise<ServiceTask> {
    const result = await firebase.functions().httpsCallable('ServiceAPI-createServiceTask')({
      tag,
      config
    });
    return result.data;
  }

  public static async deleteServiceTask(serviceId: string): Promise<ServiceTask> {
    const result = await firebase.functions().httpsCallable('ServiceAPI-deleteServiceTask')({
      serviceId
    });
    return result.data;
  }

  public static async redeemCoupon(couponId: string): Promise<Coupon> {
    const result = await firebase.functions().httpsCallable('CouponAPI-redeemCoupon')({
      couponId
    });
    return result.data;
  }
}
