























import { Component, Vue } from 'vue-property-decorator';
import { WebAPIs } from '../models/apis';
import UserStatus from '../components/UserStatus.vue';
import NewServiceTask from '../components/NewServiceTask.vue';
import ServiceTaskView from '../components/ServiceTaskView.vue';
import { ToastMessage } from '../models/toastMessage';
import { ServiceTask } from 'core';

@Component({
  components: {
    UserStatus,
    NewServiceTask,
    ServiceTaskView
  }
})
export default class ServiceList extends Vue {
  private services: ServiceTask[] = [];

  private subscribeLoading: boolean = false;
  private rawHtml: string = '';
  private serviceLoading: boolean = false;

  public async mounted() {
    await this.loadServiceTasks();
  }

  public async loadServiceTasks() {
    this.serviceLoading = true;
    try {
      const services = await WebAPIs.getUserServiceTasks();
      console.log(services);
      this.$set(this, 'services', services);
    } catch (e) {
      ToastMessage.httpRequestErrorHandler(this, e);
    }
    this.serviceLoading = false;
  }

  public async onNewTaskCreated(tag: string) {
    await this.loadServiceTasks();
  }

  public async refresh() {
    console.log('refresh');
    await this.loadServiceTasks();
  }
}
