






















import { Component, Vue } from 'vue-property-decorator';
import { ServiceTag, ServicePTTConfig } from 'core';
import ServiceConfigPTT from './ServiceConfigPTT.vue';
import { WebAPIs } from '../models/apis';
import { ToastMessage } from '../models/toastMessage';

@Component({
  components: {
    ServiceConfigPTT
  }
})
export default class ServiceList extends Vue {
  public selectItems: { text: string; value: string }[] = [];
  public selected: string = ServiceTag.PTT;
  public config: ServicePTTConfig | null = null;
  public saving: boolean = false;

  public async mounted() {
    for (const item in ServiceTag) {
      // TODO use i18n
      if (item === ServiceTag.PTT) {
        this.selectItems.push({ text: 'PTT每天自動登入', value: item });
      }
      this.selected = item;
    }
  }

  public onNewServiceTask() {
    if (this.selected === ServiceTag.PTT) {
      this.config = new ServicePTTConfig();
    }
  }

  public checkConfig(): boolean {
    if (this.config === null) {
      return false;
    }
    if (this.config.serviceTag === ServiceTag.PTT) {
      if (this.config.username === '' || this.config.password === '') {
        return false;
      }
    }
    return true;
  }

  public async createNewServiceTask() {
    if (this.config === null) {
      ToastMessage.simpleDialog(this, '錯誤', '請選擇任務類型');
      return;
    }
    if (!this.checkConfig()) {
      ToastMessage.simpleDialog(this, '錯誤', '請確認欄位輸入正確');
      return;
    }
    this.saving = true;
    try {
      await WebAPIs.createServiceTask(this.config.serviceTag, this.config);
      ToastMessage.simpleDialog(this, '成功', '創建任務成功');
      this.config = null;
      this.$emit('newTaskCreated');
    } catch (e) {
      ToastMessage.httpRequestErrorHandler(this, e);
    }
    this.saving = false;
  }
}
