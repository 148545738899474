







































import { Component, Vue, Prop } from 'vue-property-decorator';
import { WebAPIs } from '../models/apis';
import { ToastMessage } from '../models/toastMessage';
import { ServiceTask } from 'core';
import ServiceConfigPTT from './ServiceConfigPTT.vue';

@Component({
  components: {
    ServiceConfigPTT
  }
})
export default class ServiceTaskView extends Vue {
  @Prop()
  public task!: ServiceTask;

  public showConfig: boolean = false;
  public saving: boolean = false;

  public async updateServiceActive() {
    this.saving = true;
    try {
      await WebAPIs.updateServiceActive(this.task.serviceId, this.task.serviceActive);
      ToastMessage.simpleDialog(this, '成功', '更新任務成功');
    } catch (e) {
      ToastMessage.httpRequestErrorHandler(this, e);
    }
    this.saving = false;
  }

  public async updateNewServiceTask() {
    this.saving = true;
    try {
      await WebAPIs.updateServiceConfig(this.task.serviceId, this.task.serviceConfig);
      ToastMessage.simpleDialog(this, '成功', '更新任務成功');
    } catch (e) {
      ToastMessage.httpRequestErrorHandler(this, e);
    }
    this.saving = false;
  }

  public async deleteServiceTask() {
    const dialog = ToastMessage.simple('確認', '確認要刪除任務嗎');
    dialog.setButton('確定刪除', 'positive');
    dialog.setButton('放棄', 'negative');
    dialog.show(this);
    const result = await dialog.waitForResult();
    if (result === 'positive') {
      this.saving = true;
      try {
        await WebAPIs.deleteServiceTask(this.task.serviceId);
        ToastMessage.simpleDialog(this, '成功', '刪除任務成功');
        this.$emit('refresh');
      } catch (e) {
        ToastMessage.httpRequestErrorHandler(this, e);
      }
      this.saving = false;
    }
  }
}
